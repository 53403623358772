import _getPrototypeOf from "./getPrototypeOf.js";
import _setPrototypeOf from "./setPrototypeOf.js";
import _isNativeFunction from "./isNativeFunction.js";
import _construct from "./construct.js";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var getPrototypeOf = _getPrototypeOf;
var setPrototypeOf = _setPrototypeOf;
var isNativeFunction = _isNativeFunction;
var construct = _construct;

function _wrapNativeSuper(Class) {
  var _cache = typeof Map === "function" ? new Map() : undefined;

  exports = _wrapNativeSuper = function _wrapNativeSuper(Class) {
    if (Class === null || !isNativeFunction(Class)) return Class;

    if (typeof Class !== "function") {
      throw new TypeError("Super expression must either be null or a function");
    }

    if (typeof _cache !== "undefined") {
      if (_cache.has(Class)) return _cache.get(Class);

      _cache.set(Class, Wrapper);
    }

    function Wrapper() {
      return construct(Class, arguments, getPrototypeOf(this || _global).constructor);
    }

    Wrapper.prototype = Object.create(Class.prototype, {
      constructor: {
        value: Wrapper,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    return setPrototypeOf(Wrapper, Class);
  }, exports.__esModule = true, exports["default"] = exports;
  return _wrapNativeSuper(Class);
}

exports = _wrapNativeSuper, exports.__esModule = true, exports["default"] = exports;
export default exports;
export const __esModule = exports.__esModule;